import type { RecursivePartial } from '#types/utils'
import type { CheckboxConfig } from '#types/config/components/vf/checkbox'

export default {
  brandClasses: {
    base: {
      selected: 'bg-black c-white ring-black',
      selectedDisabled: 'bg-grey-30 ring-grey-30'
    },
    inverse: {
      default: 'ring-white',
      disabled: 'bg-transparent ring-grey-60',
      selected: 'bg-white c-black',
      selectedDisabled: 'bg-grey-50 ring-grey-50'
    }
  }
} satisfies RecursivePartial<CheckboxConfig>
