import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    panel: {
      wrapper: 'pb-0 !lg:p-0'
    },
    modal: {
      wrapper: 'pt-4'
    }
  }
} satisfies RecursivePartial<VfConfig['dialog']>
