import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    lastStep: 'truncate c-grey-30',
    separator: 'text-xs'
  },
  iconSize: 'md'
} satisfies RecursivePartial<VfConfig['breadcrumbs']>
