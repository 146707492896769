import { brand } from '#brand/design-system/colors.json'
import type { RecursivePartial } from '#types/utils'
import type { DashboardConfig } from '#types/config/components/dashboard'

export default {
  loyaltyButtonTheme: brand['4'],
  favorites: {
    image: {
      height: 102,
      width: 88,
    },
    thumbnail: {
      height: 47,
      width: 40,
    },
  }
} satisfies RecursivePartial<DashboardConfig['card']>
