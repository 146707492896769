import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    badge: 'm-2 shadow-sm <md:hidden',
    productNameInline: 'my-1 text-sm fw-light ~md:text-md',
    productNameModal: 'my-1 text-sm fw-light md:text-md',
    wrapperInline: 'lg:(absolute -top-8 -inset-x-2 z-3 bg-white pt-8 pb-4 px-2 shadow-md)'
  },
  carousel: {
    brandClasses: {
      item: 'snap-center'
    }
  },
  ctaSize: 'xs'
} satisfies RecursivePartial<DialogConfig['quickshop']>
