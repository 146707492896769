import type { DialogConfig } from '#types/config/components/dialog'
import type { RecursivePartial } from '#types/utils'

export default {
  hideHeader: true,
  brandClasses: {
    title: '<md:pr-8 title-1',
    wrapper: '<md:h-screen'
  },
  dialogOptions: {
    type: {
      sm: 'panel',
      md: 'modal'
    }
  }
} satisfies RecursivePartial<DialogConfig['signInConfirmation']>
