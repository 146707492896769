import { brand } from '#brand/design-system/colors.json'
import type { RecursivePartial } from '#types/utils'
import type { VfConfig } from '#types/config/components/vf'

export default {
  loyaltyCtaTheme: brand['4'],
  columns: {
    brandClasses: 'md:(px-4 gap-8)'
  },
} satisfies RecursivePartial<VfConfig['footer']>
