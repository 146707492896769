import type { SignUpConfig } from '#types/config/components/form/signUp'

export default {
  fieldsOrder: [
    'firstName',
    'lastName',
    'email',
    'zipCode',
    'phone',
    'birthDate',
    'password'
  ]
} satisfies Partial<SignUpConfig>
