import type { BopisConfig } from '#types/config/components/bopis'
import type { RecursivePartial } from '#types/utils'

export default {
  storeDetails: {
    image: {
      width: 136,
      height: 155
    }
  }
} satisfies RecursivePartial<BopisConfig>
