import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    notification: 'items-center',
    productName: 'title-5',
    productPrice: 'text-sm',
    productVariants: 'text-sm',
    promo: 'text-sm',
    savings: '-mt-5 lg:-mx-6 !b-grey-50 b-y shadow-sm',
    savingsText: 'text-sm'
  }
} satisfies RecursivePartial<DialogConfig['miniCart']>
