import type { AccountConfig } from '#types/config/pages/account'
import type { RecursivePartial } from '#types/utils'

export default {
  orderHistory: {
    brandClasses: {
      badge: 'w-6 h-6 absolute bottom-0 right-0 mb-1 mr-1 flex center b-1 b-white rounded-full bg-black text-sm c-white fw-medium md:hidden'
    },
    showQuantityBadge: true
  }
} satisfies RecursivePartial<AccountConfig>
