import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    tabs: 'pb-6 md:pb-8'
  },
  sizeChart: {
    brandClasses: {
      footerContainer: 'mt-6',
      footerDescription: 'text-sm fw-light'
    }
  },
  fits: {
    brandClasses: {
      title: 'fw-medium'
    }
  },
  measurements: {
    brandClasses: {
      type: 'uppercase title-3',
      number: 'bg-black',
      title: 'title-5',
    }
  }
} satisfies RecursivePartial<DialogConfig['sizeChart']>
