import type { CMSConfig } from '#types/config/components/cms'

export default {
  brandClasses: {
    title: 'title-4 b-t b-grey-70 py-3',
    link: 'flex items-center b-t b-grey-70 p-4 title-4'
  },
  presets: {
    red: 'c-brand-2',
    green: 'c-green-30',
  }
} satisfies CMSConfig['megaMenuL2Mobile']
