import type { CMSConfig } from '#types/config/components/cms'
import type { RecursivePartial } from '#types/utils'

export default {
  enableQuickShop: false,
  presets: {
    size: {
      width: { sm: 300, md: 200 },
      height: { sm: 349, md: 233 },
    },
    transformations: {
      unSharpMask: 150
    }
  }
} satisfies RecursivePartial<CMSConfig['productRecommendations']>
