<template>
  <div data-test-id="base-collapse">
    <define-template>
      <base-button
        v-if="$slots.toggle"
        :id
        :aria-controls="`${id}-content`"
        :aria-expanded="expanded"
        :class="classToggle"
        @click="expanded = !expanded"
      >
        <slot :expanded name="toggle" />
      </base-button>
    </define-template>
    <reuse-template v-if="!inverted" />
    <div
      :id="`${id}-content`"
      :aria-labelledby="id"
      class="overflow-hidden duration"
      :class="[
        classContent,
        {
          'max-h-0': !expanded,
          'invisible': !(expanded || props.disableVisibilityToggle),
        },
      ]"
      role="region"
      :style="{
        ...styleContent,
        ...(expanded ? {
          maxHeight: '5000px',
          transitionDuration: '1s',
        } : {}),
      }"
    >
      <slot :expanded />
    </div>
    <reuse-template v-if="inverted" />
  </div>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'

const props = defineProps<{
  /**
   * Allows using collapse as part of the group when only one item can be expanded at a time.
   * Each collapse in a group should have a unique value.
   * All collapses in a group should be binded to the same model.
   */
  value?: string
  /**
   * Whether the collapse toggle should appear before or after its associated content
   */
  inverted?: boolean
  /**
   * Whether content should have css visibility toggling disabled
   * Can be used to allow showing content partially but allows focusable content to tabbed to when component is collapsed as side effect
   */
  disableVisibilityToggle?: boolean
  /**
   * Classes to be applied to toggle button
   */
  classToggle?: CSSClass
  /**
   * Classes to be applied to content container
   */
  classContent?: CSSClass
  /**
   * Inline styles to be applied to content container
   */
  styleContent?: Record<string, string>
}>()

defineSlots<{
  default: (props: { expanded: boolean }) => void
  toggle: (props: { expanded: boolean }) => void
}>()

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
const model = defineModel<boolean | string>()
const expanded = computed({
  get: () => props.value ? props.value === model.value : !!model.value,
  set: (value) => {
    if (props.value)
      model.value = model.value === props.value ? '' : props.value
    else
      model.value = value
  }
})

// Temporary fix as per https://github.com/nuxt/nuxt/issues/25755
const id = `base-collapse-${useId().replace('_', '-')}`
</script>
