import type { ApplePayButton } from '#types/components/checkout/applePayButton'
import type { RecursivePartial } from '#types/utils'

export default {
  size: {
    sm: 'sm',
    lg: 'md'
  },
  variant: 'tertiary'
} satisfies RecursivePartial<ApplePayButton>
