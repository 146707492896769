import type { CheckoutConfig } from '#types/config/checkout'

export default {
  NORA: {
    siteId: {
      DEV: '09c23d645068',
      QA: '09c23d645068',
      PREPROD: '09c23d645068',
      PROD: 'af35c9d85db6'
    }
  }
} satisfies CheckoutConfig['forter']
