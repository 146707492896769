import type { BopisConfig } from '#types/config/components/bopis'
import type { RecursivePartial } from '#types/utils'

export default {
  storeDetails: {
    brandClasses: {
      addressLineSection: 'mt-4 text-sm',
      columnWrapper: 'lg:cols-6',
      locationSection: 'col-span-3',
      storeSection: 'fw-medium',
      storeAddress: 'text-sm fw-light',
      storeDirectionAndPhone: 'c-black',
      storeHours: 'fw-medium',
      storeHoursContent: 'fw-light',
      storeProductTitle: 'fw-medium mb-5',
      productAttributes: 'c-black fw-light mb-1',
      storeInstruction: 'fw-medium',
      storeCopy: 'fw-light'
    },
    image: {
      width: 136,
      height: 136
    }
  }
} satisfies RecursivePartial<BopisConfig>
