import { CreditCardTypes } from '#commerce/utils/creditCardTypes'
import type { CheckoutConfig } from '#types/config/checkout'
import type { CreditCardConfig } from '#types/creditCard'
import type { RecursivePartial } from '#types/utils'

export default {
  allowed: {
    CA: [
      CreditCardTypes.DISCOVER,
      CreditCardTypes.DINERS
    ]
  },
  imageGroup: {
    CA: (defaultValue: CreditCardConfig[]): CreditCardConfig[] => [
      ...defaultValue,
      CreditCardTypes.DISCOVER,
      CreditCardTypes.DINERS
    ],
    US: (defaultValue: CreditCardConfig[]): CreditCardConfig[] => [
      ...defaultValue,
      CreditCardTypes.DINERS
    ]
  },
  collapsibleAcceptedCards: {
    EMEA: false,
    NORA: true
  }
} satisfies RecursivePartial<CheckoutConfig['creditCards']>
