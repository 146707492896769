import type { CartConfig } from '#types/config/components/cart'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    editItemCta: 'mt-4 flex items-center gap-2 underlined <lg:hidden text-sm',
    header: 'b-grey-70 mb-4 lg:mb-6',
    headerContent: 'w-full p-0',
    headerTitle: 'title-2',
    headerTotalItems: 'fw-bold flex items-center justify-end <md:text-sm',
    items: 'pb-8 md:pb-10',
    itemsPerShipping: 'md:mb-4',
    itemVariants: 'c-grey-20',
    noItems: '-mt-4 lg:-mt-6 flex center text-center subtitle-3'
  },
  brandStyles: {
    noItems: {
      h: {
        sm: '11.5rem',
        md: '15.375rem',
        lg: '15.875rem'
      }
    }
  },
  coupon: {
    buttonVariant: 'tertiary'
  },
  disableBonusPdpLinks: true,
  item: {
    links: 'text-sm',
    actionMenu: {
      brandClasses: {
        button: 'py-3 px-0 md:py-4 gap-3',
        list: 'divide-grey-70 px-4'
      }
    }
  },
  loyalty: {
    showEachRewardInSummary: true,
    showLoyaltyOnEmptyCart: false
  },
  outOfStock: {
    showRemoveAll: false,
    showSaveForLater: false
  },
  orderSummary: {
    header: 'text-md fw-bold',
    subtotal: 'text-sm',
    total: 'uppercase text-sm fw-bold'
  },
  showTotalInHeader: false,
  thumbnails: {
    size: { width: 94, height: 110 }
  },
} satisfies RecursivePartial<CartConfig>
