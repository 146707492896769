import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    rewardsPreviewTileButton: 'b-grey-70 rounded-sm @hover:(b-grey-70 bg-white c-black)',
    rewardsPreviewTilePopoverContent: 'z-overlay <md:px-2',
    rewardsPreviewTilePopoverContentInner: 'rounded-sm b-1 b-grey-70 bg-white p-4 c-grey-10 shadow-xs'
  },
  logo: 'https://assets.thenorthface.com/image/upload/fl_sanitize/v1730476435/xplr_logo_circle.svg',
  showLoyaltyToastOnlyOnPDP: false
} satisfies RecursivePartial<VfConfig['loyalty']>
