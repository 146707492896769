import type { CheckoutConfig } from '#types/config/components/checkout'

export default {
  logoVariant: 'default',
  showNeedHelp: true,
  helpUrl: '/help/contact-us',
  securityIcons: {
    requiredLocales: [
      'en-us',
      'en-ca',
      'fr-ca'
    ]
  },
  brandClasses: {
    container: 'grid md:(grid-flow-col px-10)',
    needHelp: 'fw-medium <md:order-first md:(col-span-1 text-right)',
    logoLink: '<md:py-6 md:row-span-2'
  }
} satisfies CheckoutConfig['footer']
