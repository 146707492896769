import type { CMSConfig } from '#types/config/components/cms'

export default {
  classControls: 'mx-4 rounded-full bg-white/50 p-2 shadow-sm',
  spacing: {
    none: 0,
    small: 0.5,
    medium: 1,
    large: 1.5
  }
} satisfies CMSConfig['collection']
