import type { ProductListConfig } from '#types/config/components/product/list'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    filterAndSortCta: 'b-transparent bg-grey-90 px-8 fw-light',
    innerGridWrapper: 'grow pb-10',
    noResults: 'mb-6 mt-10 px-10 text-center text-lg fw-bold lg:fw-medium',
    pagination: 'mt-10'
  },
  filterAndSortCta: {
    iconSize: 'md'
  },
  grid: {
    gaps: {
      y: 'md'
    }
  },
  scrollToElementOptions: { offset: 0 }
} satisfies RecursivePartial<ProductListConfig>
