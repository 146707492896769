import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'
import { brand } from '#brand/design-system/colors.json'

export default {
  mediaSize: {
    width: 344,
    height: 400
  },
  mediaTransformations: {
    unSharpMask: 150
  },
  signInToBuyCta: {
    theme: brand['4']
  }
} satisfies RecursivePartial<ProductConfig['card']>
