import type { RecursivePartial } from '#types/utils'
import type { ProductUpsellConfig } from '#types/config/components/product/upsell'

export default {
  brandClasses: {
    container: 'p-4 mb-3 bg-grey-90',
    details: {
      button: 'underlined fw-normal block',
      link: 'fw-normal',
      text: 'fw-light'
    },
    title: 'subtitle-5'
  },
  checkboxSize: 'md',
  image: {
    height: 68,
    width: 68
  },
  buttonSize: 'tiny',
  buttonVariant: 'tertiary'
} satisfies RecursivePartial<ProductUpsellConfig>
