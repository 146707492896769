import type { CheckoutConfig } from '#types/config/checkout'

export default {
  logo: 'https://assets.thenorthface.com/image/upload/fl_sanitize/v1730476435/xplr_logo_circle.svg',
  brandClasses: {
    ctas: 'pt-2 fw-normal underlined lh-3',
    header: 'fw-medium',
    logo: 'shrink-0 mr-4',
    wrapper: 'max-w-full space-x-1 ws-pre-wrap fw-light text-sm',
  }
} satisfies CheckoutConfig['loyalty']
