import type { RecursivePartial } from '#types/utils'
import type { BenefitsConfig } from '#types/config/components/loyalty/benefits'

export default {
  benefits: [
    {
      icon: '/img/benefits/shipping.svg',
      text: 'loyaltyBenefitsFreeShipping',
    },
    {
      icon: '/img/benefits/earn.svg',
      text: 'loyaltyBenefitsEarnPoints',
    },
    {
      icon: '/img/benefits/gear.svg',
      text: 'loyaltyBenefitsGetAccess',
    },
    {
      icon: '/img/benefits/gift.svg',
      text: 'loyaltyBenefitsBirthdayDiscount',
    }
  ],
} satisfies RecursivePartial<BenefitsConfig>
