import type { RecursivePartial } from '#types/utils'
import type { PdpConfig } from '#types/config/pages/pdp'

export default {
  gallery: {
    presets: {
      src: {
        width: 344,
        height: 400,
        unSharpMask: 100
      },
      srcZoom: {
        width: 2150,
        height: 2500,
        excludeDPR: true,
        sharpen: 0
      }
    },
    videoFit: 'cover',
  }
} satisfies RecursivePartial<PdpConfig>
